import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearOneStepA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber + secondNumber;
      let sign = "+"

      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign = "-";
      }       

      generatedProblem.push({
        key: `${step}-losa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$x ${equation.sign} ${equation.secondNumber} = ${equation.result}$`,
      answer: `$x = ${equation.firstNumber}$`
    };
  }

  function helpText() {
    return `

    $$ x + 3 = 5 $$       
    \
    $$ \\hspace{6mm} -3 \\hspace{6mm} -3 $$
    \
    $$ x + 0 = 5 - 3 $$
    \
    $$ x = 2 $$

    `
  }

  return (
    <div>
     
      <EquationLayout
        title={"Linear One Step A"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "One Step A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}