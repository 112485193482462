import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MathJax } from "better-react-mathjax";

export default function EquationLayout({ title = null, instructions = null, formattedProblems, viewAnswers, numCols = 2, displayAnswerInline = false }) {

  function displaySingleEquation(problem, index) {
    let equalSign = `$=$`

    if (displayAnswerInline) {
      return (
        <Col key={problem.key}>
          <div>
            <span>{index + 1}.)&nbsp;</span><span>{problem.equation}</span>{viewAnswers ? (<span> {equalSign} {problem.answer}</span>) : <br/>}
            <div></div>
            <br/>
          </div>
        </Col>
      )
    } else {
      return (
        <Col key={problem.key}>
          <div>
            <span>{index + 1}.)&nbsp;</span><span>{problem.equation}</span>
            <div>{viewAnswers ? (<span>{problem.answer}</span>) : <br/>}</div>
            <br/>
          </div>
        </Col>
      )
    }
  }

  function displaySingleEquationHidden(problem, index) {
    return (
      <Col key={`${problem.key}-eq`}>
        <div>
          <span>{index + 1}.)&nbsp;</span><span>{problem.equation}</span>
          <div><br/></div>
          <br/>
        </div>
      </Col>
    )
  }

  function displaySingleEquationWithAnswerHidden(problem, index) {
    let equalSign = `$=$`

    if (displayAnswerInline) {
      return (
        <Col key={`${problem.key}-eq-ans`}>
          <div>
            <span>{index + 1}.)&nbsp;</span><span>{problem.equation}</span><span> {equalSign} {problem.answer}</span>
            <div></div>
            <br/>
          </div>
        </Col>
      )
    } else {
      return (
        <Col key={`${problem.key}-eq-ans`}>
          <div>
            <span>{index + 1}.)&nbsp;</span><span>{problem.equation}</span>
            <div><span>{problem.answer}</span></div>
            <br/>
          </div>
        </Col>
      )
    }
  }

  return (
    <span>
      <MathJax dynamic>
        <h1>{title}</h1>
        <span className="solve-for-text">{instructions}</span>
        <Row md={numCols}>
          {formattedProblems.map((problem, index) => displaySingleEquation(problem, index))}
        </Row>
        <span id="pdf-equations" style={{display: 'none'}}>
          <h1>{title}</h1>
          <span className="solve-for-text">{instructions}</span>
          <Row md={numCols}>
            {formattedProblems.map((problem, index) => displaySingleEquationHidden(problem, index))}
          </Row>
        </span>
        <span id="pdf-equations-with-answers" style={{display: 'none'}}>
          <h1>{title}</h1>
          <span className="solve-for-text">{instructions}</span>
          <Row md={numCols}>
            {formattedProblems.map((problem,index) => displaySingleEquationWithAnswerHidden(problem, index))}
          </Row>
        </span>
      </MathJax>
    </span>
  )
}
