import React, { useState } from 'react';

import PolynomialQuadraticOneStep from './PolynomialQuadraticOneStep';
import PolynomialQuadraticTwoStep from './PolynomialQuadraticTwoStep';
import PolynomialQuadraticThreeStep from './PolynomialQuadraticThreeStep';
import PolynomialFoil from './PolynomialFoil';
import PolynomialUnfoil from './PolynomialUnfoil';
import PolynomialParabola from './PolynomialParabola';
import PolynomialCompleteTheSquare from './PolynomialCompleteTheSquare';
import PolynomialQuadraticFunctions from './PolynomialQuadraticFunctions';
import PolynomialCubic from './PolynomialCubic';
import PolynomialCubicFunctions from './PolynomialCubicFunctions';
import PolynomialFeatures from './PolynomialFeatures';
import PolynomialQuartic from './PolynomialQuartic';
import PolynomialDivision from './PolynomialDivision';
import Button from 'react-bootstrap/Button';

const QUADRATIC_ONE_STEP = "quadratic_one_step";
const QUADRATIC_TWO_STEP = "quadratic_two_step";
const QUADRATIC_THREE_STEP = "quadratic_three_step";
const FOIL = "foil";
const UNFOIL = "unfoil";
const PARABOLA = "parabola";
const COMPLETE_THE_SQUARE = "complete_the_square";
const QUADRATIC_FUNCTIONS = "quadratic_functions"
const CUBIC = "cubic";
const CUBIC_FUNCTIONS = "cubic_functions"
const FEATURES = "features";
const QUARTIC = "quartic";
const DIVISION = "division";
const FUNCTION_TYPES = [QUADRATIC_ONE_STEP, QUADRATIC_TWO_STEP, QUADRATIC_THREE_STEP, FOIL, UNFOIL, PARABOLA, 
  COMPLETE_THE_SQUARE, QUADRATIC_FUNCTIONS, CUBIC, CUBIC_FUNCTIONS, FEATURES, QUARTIC, DIVISION];

const functionTypeDisplayNames = {
  "quadratic_one_step": "Quadratic One Step",
  "quadratic_two_step": "Quadratic Two Step",
  "quadratic_three_step": "Quadratic Three Step",
  "foil": "Foil",
  "unfoil": "Unfoil",
  "parabola": "Parabola",
  "complete_the_square": "Complete The Square",
  "quadratic_functions": "Quadratic Functions",
  "cubic": "Cubic",
  "cubic_functions": "Cubic Functions",
  "features": "Features",
  "quartic": "Quartic",
  "division": "Division"
}

export default function Polynomial() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(QUADRATIC_ONE_STEP);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case QUADRATIC_ONE_STEP: {
        return <PolynomialQuadraticOneStep />;
      }
      case QUADRATIC_TWO_STEP: {
        return <PolynomialQuadraticTwoStep />;
      }
      case QUADRATIC_THREE_STEP: {
        return <PolynomialQuadraticThreeStep />;
      }
      case FOIL: {
        return <PolynomialFoil />;
      }
      case UNFOIL: {
        return <PolynomialUnfoil />;
      }
      case PARABOLA: {
        return <PolynomialParabola />;
      }
      case COMPLETE_THE_SQUARE: {
        return <PolynomialCompleteTheSquare />;
      }
      case QUADRATIC_FUNCTIONS: {
        return <PolynomialQuadraticFunctions />;
      }
      case CUBIC: {
        return <PolynomialCubic />;
      }
      case CUBIC_FUNCTIONS: {
        return <PolynomialCubicFunctions />;
      }
      case FEATURES: {
        return <PolynomialFeatures />;
      }
      case QUARTIC: {
        return <PolynomialQuartic />;
      }
      case DIVISION: {
        return <PolynomialDivision />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}