import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialOneStep() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.abs(secondNumber)-1;
      let sign1 = "";
      let sign2 = "";
      let sign3 = "+";
      let sign4 = "";
      let sign5 = "+";

      if(firstNumber < 0) {
        firstNumber = Math.abs(firstNumber);
        sign1 = "-";
      }
      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign2 = "";
      }
      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign3 = "-";
      }
      if(fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign4 = "";
      }
      if(fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        sign5 = "-";
      }

      if (secondNumber === fourthNumber) {
        step--;
        continue;
      }
    
      generatedProblem.push({
        key: `${step}-pos`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        result1: result1,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3,
        sign4: sign4,
        sign5: sign5
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let degree = Math.max(equation.secondNumber, equation.fourthNumber)
    let leadingCoefficient = degree === equation.secondNumber ? equation.firstNumber : equation.thirdNumber
    let leadingCoefficientSign = leadingCoefficient === equation.firstNumber ? equation.sign1 : equation.sign3
    leadingCoefficientSign = leadingCoefficientSign === "+" ? "" : leadingCoefficientSign

    let endBehavior = ""
    if(leadingCoefficientSign === "-"){
      endBehavior = degree % 2 === 0 ? "Both Down" : "Up/Left, Down/Right"
    }
    else {
      endBehavior = degree % 2 === 0 ? "Both Up" : "Up/Right, Down/Left"
    }

    let answerToDisplay = (
      <span>
      <span>{`$\\text{Degree} = ${equation.sign2}${degree}$`}</span>
      <br/>
      <span>{`$\\text{Leading Coefficient} = ${leadingCoefficientSign}${leadingCoefficient}$`}</span>
      <br/>
      <span>{`$ \\text{Y-Intercept} = (0,${equation.sign5 === "+" ? "" : equation.sign5} ${equation.fifthNumber})$`}</span>
      <br/>
      <span>{`$ \\text{Maximum Number of X - Intercepts} = ${degree}$`}</span>
      <br/>
      <span>{`$ \\text{Maximum Number of Extrema} = ${degree - 1}$`}</span>
      <br/>
      <span>{`$ \\text{End Behavior} = \\text{${endBehavior}}$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$f(x) = ${equation.sign1}${equation.firstNumber}x^{${equation.secondNumber}}${equation.sign3}${equation.thirdNumber}x^{${equation.fourthNumber}}${equation.sign5}${equation.fifthNumber}$`,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
      $$ \\text{Degree = Largest Exponent} $$    
      \
      $$ \\text{Leading Coefficient = Number in front of Degree} $$
      \
      $$ \\text{Y - Intercept = Set x equal to 0} $$
      \
      $$ \\text{Maximum Number of X - Intercepts = Degree} $$
      \
      $$ \\text{Maximum Number of Extrema = Degree - 1} $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Features"}
        instructions={"List all features."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Features Help", helpTextFunc: helpText}}
      />
    </div>
  );
}