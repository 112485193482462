import React, { useState } from 'react';

import ArithmeticAdditionA from './ArithmeticAdditionA';
import ArithmeticAdditionB from './ArithmeticAdditionB';
import ArithmeticAdditionC from './ArithmeticAdditionC';
import ArithmeticSubtractionA from './ArithmeticSubtractionA';
import ArithmeticSubtractionB from './ArithmeticSubtractionB';
import ArithmeticSubtractionC from './ArithmeticSubtractionC';
import ArithmeticSubtractionD from './ArithmeticSubtractionD';
import ArithmeticMultiplicationA from './ArithmeticMultiplicationA';
import ArithmeticMultiplicationB from './ArithmeticMultiplicationB';
import ArithmeticMultiplicationC from './ArithmeticMultiplicationC';
import ArithmeticDivisionA from './ArithmeticDivisionA';
import ArithmeticDivisionB from './ArithmeticDivisionB';
import ArithmeticAddingFractions from './ArithmeticAddingFractions';
import ArithmeticSubtractingFractions from './ArithmeticSubtractingFractions';
import ArithmeticMultiplyingFractions from './ArithmeticMultiplyingFractions';
import ArithmeticDividingFractions from './ArithmeticDividingFractions';
import ArithmeticAssociativeFractionsA from './ArithmeticAssociativeFractionsA';
import ArithmeticAssociativeFractionsB from './ArithmeticAssociativeFractionsB';
import ArithmeticNegatives from './ArithmeticNegatives';
import ArithmeticSquares from './ArithmeticSquares';
import ArithmeticCubes from './ArithmeticCubes';
import ArithmeticSquareRoots from './ArithmeticSquareRoots';
import ArithmeticCubeRoots from './ArithmeticCubeRoots';
import ArithmeticCancel2 from './ArithmeticCancel2';
import ArithmeticCancel3 from './ArithmeticCancel3';
import ArithmeticCancel5 from './ArithmeticCancel5';
import ArithmeticReduceFractions from './ArithmeticReduceFractions';
import ArithmeticImaginaryNumbers from './ArithmeticImaginaryNumbers';
import ArithmeticPercentA from './ArithmeticPercentA';
import ArithmeticPercentB from './ArithmeticPercentB';
import ArithmeticRounding from './ArithmeticRounding';
import ArithmeticModular from './ArithmeticModular';
import ArithmeticPrimeFactorization from './ArithmeticPrimeFactorization';
import ArithmeticFactorialA from './ArithmeticFactorialA';
import ArithmeticFactorialB from './ArithmeticFactorialB';
import Button from 'react-bootstrap/Button';

const ADDITIONA = "addition a";
const ADDITIONB = "addition b";
const ADDITIONC = "addition c";
const SUBTRACTIONA = "subtraction a";
const SUBTRACTIONB = "subtraction b";
const SUBTRACTIONC = "subtraction c";
const SUBTRACTIOND = "subtraction d";
const MULTIPLICATIONA = "multiplication a";
const MULTIPLICATIONB = "multiplication b";
const MULTIPLICATIONC = "multiplication c";
const DIVISIONA = "division a";
const DIVISIONB =  "division b";
const ADDINGFRACTIONS = "adding fractions";
const SUBTRACTINGFRACTIONS = "subtracting fractions";
const MULTIPLYINGFRACTIONS = "multiplying fractions";
const DIVIDINGFRACTIONS = "dividing fractions";
const ASSOCIATIVEFRACTIONSA = "associative fractions a";
const ASSOCIATIVEFRACTIONSB = "associative fractions b";
const NEGATIVES = "negatives";
const SQUARES = "squares";
const CUBES = "cubes";
const SQUAREROOTS = "square roots";
const CUBEROOTS = "cube roots";
const CANCEL2 = "cancel 2"
const CANCEL3 = "cancel 3"
const CANCEL5 = "cancel 5"
const REDUCEFRACTIONS = "reduce fractions";
const IMAGINARYNUMBERS = "imaginary numbers";
const PERCENTA = "percent a";
const PERCENTB = "percent b";
const ROUNDING = "rounding";
const MODULAR = "modular";
const PRIMEFACTORIZATION = "prime factorization";
const FACTORIALA = "factorial a";
const FACTORIALB = "factorial b";
const FUNCTION_TYPES = [
  ADDITIONA, ADDITIONB, ADDITIONC, SUBTRACTIONA, SUBTRACTIONB, SUBTRACTIONC, SUBTRACTIOND, MULTIPLICATIONA, 
  MULTIPLICATIONB, MULTIPLICATIONC, DIVISIONA, DIVISIONB, ADDINGFRACTIONS, SUBTRACTINGFRACTIONS,
  MULTIPLYINGFRACTIONS, DIVIDINGFRACTIONS, ASSOCIATIVEFRACTIONSA, ASSOCIATIVEFRACTIONSB, NEGATIVES,
  SQUARES, CUBES, SQUAREROOTS, CUBEROOTS, CANCEL2, CANCEL3, CANCEL5, REDUCEFRACTIONS, IMAGINARYNUMBERS, 
  PERCENTA, PERCENTB, ROUNDING, MODULAR, PRIMEFACTORIZATION, FACTORIALA, FACTORIALB
];

const functionTypeDisplayNames = {
  "addition a": "Addition A",
  "addition b": "Addition B",
  "addition c": "Addition C",
  "subtraction a": "Subtraction A",
  "subtraction b": "Subtraction B",
  "subtraction c": "Subtraction C",
  "subtraction d": "Subtraction D",
  "multiplication a": "Multiplication A",
  "multiplication b": "Multiplication B",
  "multiplication c": "Multiplication C",
  "division a": "Division A",
  "division b": "Division B",
  "adding fractions": "Adding Fractions",
  "subtracting fractions": "Subtracting Fractions",
  "multiplying fractions": "Multiplying Fractions",
  "dividing fractions": "Dividing Fractions",
  "associative fractions a": "Associative Fractions A",
  "associative fractions b": "Associative Fractions B",
  "negatives": "Negatives",
  "squares": "Squares",
  "cubes": "Cubes",
  "square roots": "Square Roots",
  "cube roots": "Cube Roots",
  "cancel 2": "Cancel 2",
  "cancel 3": "Cancel 3",
  "cancel 5": "Cancel 5",
  "reduce fractions": "Reduce Fractions",
  "imaginary numbers": "Imaginary Numbers",
  "percent a": "Percent A",
  "percent b": "Percent B",
  "rounding": "Rounding",
  "modular": "Modular",
  "prime factorization": "Prime Factorization",
  "factorial a": "Factorial A",
  "factorial b": "Factorial B"
}

export default function Arithmetic() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(ADDITIONA);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case ADDITIONA: {
        return <ArithmeticAdditionA />;
      }
      case ADDITIONB: {
        return <ArithmeticAdditionB />;
      }
      case ADDITIONC: {
        return <ArithmeticAdditionC/>;
      }
      case SUBTRACTIONA: {
        return <ArithmeticSubtractionA />
      }
      case SUBTRACTIONB: {
        return <ArithmeticSubtractionB />
      }
      case SUBTRACTIONC: {
        return <ArithmeticSubtractionC />
      }
      case SUBTRACTIOND: {
        return <ArithmeticSubtractionD />
      }
      case MULTIPLICATIONA: {
        return <ArithmeticMultiplicationA />;
      }
      case MULTIPLICATIONB: {
        return <ArithmeticMultiplicationB />;
      }
      case MULTIPLICATIONC: {
        return <ArithmeticMultiplicationC />;
      }
      case DIVISIONA: {
        return <ArithmeticDivisionA />;
      }
      case DIVISIONB: {
        return <ArithmeticDivisionB />;
      }
      case ADDINGFRACTIONS: {
        return <ArithmeticAddingFractions />;
      }
      case SUBTRACTINGFRACTIONS: {
        return <ArithmeticSubtractingFractions />;
      }
      case MULTIPLYINGFRACTIONS: {
        return <ArithmeticMultiplyingFractions />;
      }
      case DIVIDINGFRACTIONS: {
        return <ArithmeticDividingFractions />;
      }
      case ASSOCIATIVEFRACTIONSA: {
        return <ArithmeticAssociativeFractionsA />;
      }
      case ASSOCIATIVEFRACTIONSB: {
        return <ArithmeticAssociativeFractionsB />;
      }
      case NEGATIVES: {
        return <ArithmeticNegatives />;
      }
      case SQUARES: {
        return <ArithmeticSquares />;
      }
      case CUBES: {
        return <ArithmeticCubes />;
      }
      case SQUAREROOTS: {
        return <ArithmeticSquareRoots />;
      }
      case CUBEROOTS: {
        return <ArithmeticCubeRoots />;
      }
      case CANCEL2: {
        return <ArithmeticCancel2 />;
      }
      case CANCEL3: {
        return <ArithmeticCancel3 />;
      }
      case CANCEL5: {
        return <ArithmeticCancel5 />;
      }
      case REDUCEFRACTIONS: {
        return <ArithmeticReduceFractions />;
      }
      case IMAGINARYNUMBERS: {
        return <ArithmeticImaginaryNumbers />;
      }
      case PERCENTA: {
        return <ArithmeticPercentA />;
      }
      case PERCENTB: {
        return <ArithmeticPercentB />;
      }
      case ROUNDING: {
        return <ArithmeticRounding />;
      }
      case MODULAR: {
        return <ArithmeticModular />;
      }
      case PRIMEFACTORIZATION: {
        return <ArithmeticPrimeFactorization />;
      }
      case FACTORIALA: {
        return <ArithmeticFactorialA />;
      }
      case FACTORIALB: {
        return <ArithmeticFactorialB />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}