import React, { useState } from 'react';

import CalculusAverageRateofChange from './CalculusAverageRateofChange';
import CalculusPowerRule from './CalculusPowerRule';
import CalculusProductRule from './CalculusProductRule';
import CalculusQuotientRule from './CalculusQuotientRule';
import CalculusChainRule from './CalculusChainRule';
import CalculusIndefiniteIntegral from './CalculusIndefiniteIntegral';
import CalculusDefiniteIntegral from './CalculusDefiniteIntegral';
import CalculusSeries from './CalculusSeries';
import CalculusSeparableODE from './CalculusSeparableODE';
import CalculusSecondOrderODE from './CalculusSecondOrderODE';
import Button from 'react-bootstrap/Button';

const AVERAGE_RATE_OF_CHANGE = "average_rate_of_change"
const POWER_RULE = "power_rule"
const PRODUCT_RULE = "product_rule"
const QUOTIENT_RULE = "quotient_rule"
const CHAIN_RULE = "chain_rule"
const INDEFINITE_INTEGRAL = "indefinite_integral"
const DEFINITE_INTEGRAL = "definite_integral"
const SERIES = "series"
const SEPARABLE_ODE = "separable_ode"
const SECOND_ORDER_ODE = "second_order_ode"
const FUNCTION_TYPES = [
  AVERAGE_RATE_OF_CHANGE, POWER_RULE, PRODUCT_RULE, QUOTIENT_RULE, CHAIN_RULE, INDEFINITE_INTEGRAL,
  DEFINITE_INTEGRAL, SERIES, SEPARABLE_ODE, SECOND_ORDER_ODE
];

const functionTypeDisplayNames = {
  "average_rate_of_change" : "Average Rate of Change",
  "power_rule": "Power Rule",
  "product_rule": "Product Rule",
  "quotient_rule": "Quotient Rule",
  "chain_rule": "Chain Rule",
  "indefinite_integral": "Indefinite Integral",
  "definite_integral": "Definite Integral",
  "series": "Series",
  "separable_ode": "Separable ODE",
  "second_order_ode": "Second Order ODE"
}

export default function Calculus() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(POWER_RULE);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case AVERAGE_RATE_OF_CHANGE: {
        return <CalculusAverageRateofChange />;
      }
      case POWER_RULE: {
        return <CalculusPowerRule />;
      }
      case PRODUCT_RULE: {
        return <CalculusProductRule />;
      }
      case QUOTIENT_RULE: {
        return <CalculusQuotientRule />;
      }
      case CHAIN_RULE: {
        return <CalculusChainRule />;
      }
      case INDEFINITE_INTEGRAL: {
        return <CalculusIndefiniteIntegral />;
      }
      case DEFINITE_INTEGRAL: {
        return <CalculusDefiniteIntegral />;
      }
      case SERIES: {
        return <CalculusSeries />;
      }
      case SEPARABLE_ODE: {
        return <CalculusSeparableODE />;
      }
      case SECOND_ORDER_ODE: {
        return <CalculusSecondOrderODE />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}