import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction, randomIntFromInterval, simplifyFraction } from '../../../../utils/mathHelpers';

export default function RationalFeatures() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    let formattedProblemList = [
      equationFormatter1(),
      equationFormatter2(),
      equationFormatter3(),
      equationFormatter4()
    ]

    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter1() {
    let firstNumber = randomIntFromInterval(2, 12)
    let secondNumber = randomIntFromInterval(2, 12)
    let thirdNumber = randomIntFromInterval(2, 12)

    let frac1 = displayFraction(firstNumber, thirdNumber)
    let frac2 = displayFraction(thirdNumber, secondNumber)
    let answerToDisplay = (
      <span>
        <span>{`$X-Intercept: None$`}</span>
        <br/>
        <span>{`$Y-Intercept: (0, - ${frac1})$`}</span>
        <br/>
        <span>{`$Holes: None$`}</span>
        <br/>
        <span>{`$Horizontal \\: Asymptote: y = 0$`}</span>
        <br/>
        <span>{`$Vertical \\: Asymptote: x = ${frac2}$`}</span>
        <br/>
        <span>{`$Domain: (-\\infty, ${frac2}) \\cup (${frac2}, + \\infty)$`}</span>
        <br/>
        <span>{`$Range: (-\\infty, 0) \\cup (0, + \\infty)$`}</span>
        <br/>
      </span>
    )

    return {
      key: '1-rf',
      equation: `$y = \\dfrac{${firstNumber}}{${secondNumber}x - ${thirdNumber}}$`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter2() {
    let firstNumber = randomIntFromInterval(2, 12)
    let secondNumber = randomIntFromInterval(2, 12)
    let thirdNumber = randomIntFromInterval(2, 12)
    let fourthNumber = randomIntFromInterval(2, 12)

    while ((secondNumber/firstNumber) == (fourthNumber/thirdNumber)) {
      firstNumber = randomIntFromInterval(2, 12)
      secondNumber = randomIntFromInterval(2, 12)
      thirdNumber = randomIntFromInterval(2, 12)
      fourthNumber = randomIntFromInterval(2, 12)
    }

    let frac1 = displayFraction(secondNumber, firstNumber)
    let frac2 = displayFraction(secondNumber, fourthNumber)
    let frac3 = displayFraction(firstNumber, thirdNumber)
    let frac4 = displayFraction(fourthNumber, thirdNumber)
    let answerToDisplay = (
      <span>
        <span>{`$X-Intercept: (${frac1}, 0)$`}</span>
        <br/>
        <span>{`$Y-Intercept: (0, ${frac2})$`}</span>
        <br/>
        <span>{`$Holes: None$`}</span>
        <br/>
        <span>{`$Horizontal \\: Asymptote: y = ${frac3}$`}</span>
        <br/>
        <span>{`$Vertical \\: Asymptote: x = ${frac4}$`}</span>
        <br/>
        <span>{`$Domain: (-\\infty, ${frac4}) \\cup (${frac4}, + \\infty)$`}</span>
        <br/>
        <span>{`$Range: (-\\infty, ${frac3}) \\cup (${frac3}, + \\infty)$`}</span>
        <br/>
      </span>
    )

    return {
      key: '2-rf',
      equation: `$y = \\dfrac{${firstNumber}x - ${secondNumber}}{${thirdNumber}x - ${fourthNumber}}$`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter3() {
    let firstNumber = randomIntFromInterval(2, 12)
    let secondNumber = randomIntFromInterval(2, 12)
    let thirdNumber = randomIntFromInterval(2, 12)
    let fourthNumber = randomIntFromInterval(2, 12)

    let frac1 = displayFraction(1, fourthNumber)
    let frac2 = displayFraction(secondNumber, firstNumber)
    let frac3 = displayFraction(firstNumber, ((secondNumber*thirdNumber) - (firstNumber * fourthNumber)))
    let frac4 = displayFraction(fourthNumber, thirdNumber)

    // frac4 frac2
    let simplifiedFrac2 = simplifyFraction(secondNumber, firstNumber).filter(n => n)
    let simplifiedFrac2Value = simplifiedFrac2[0] / (simplifiedFrac2[1] === null ? 1 : simplifiedFrac2[1])
    let simplifiedFrac4 = simplifyFraction(fourthNumber, thirdNumber).filter(n => n)
    let simplifiedFrac4Value = simplifiedFrac4[0] / (simplifiedFrac4[1] === null ? 1 : simplifiedFrac4[1])
    let domainBigFrac = frac2
    let domainSmallFrac = frac4
    if (simplifiedFrac4Value > simplifiedFrac2Value) {
      domainBigFrac = frac4
      domainSmallFrac = frac2
    }

    let simplifiedFrac3 = simplifyFraction(firstNumber, ((secondNumber*thirdNumber) - (firstNumber * fourthNumber)))
    let simplifiedFrac3Value = simplifiedFrac3[0]/simplifiedFrac3[1]
    let range = `$Range: (-\\infty, 0) \\cup (0, ${frac3}) \\cup (${frac3}, +\\infty)$`
    if(simplifiedFrac3Value < 0) {
      range = `$Range: (-\\infty, ${frac3}) \\cup (${frac3}, 0) \\cup (0, +\\infty)$`
    }

    let answerToDisplay = (
      <span>
        <span>{`$X-Intercept: None$`}</span>
        <br/>
        <span>{`$Y-Intercept: (0, -${frac1})$`}</span>
        <br/>
        <span>{`$Holes: (${frac2}, ${frac3})$`}</span>
        <br/>
        <span>{`$Horizontal \\: Asymptote: y = 0$`}</span>
        <br/>
        <span>{`$Vertical \\: Asymptote: x = ${frac4}$`}</span>
        <br/>
        <span>{`$Domain: (-\\infty, ${domainSmallFrac}) \\cup (${domainSmallFrac}, ${domainBigFrac}) \\cup (${domainBigFrac}, + \\infty)$`}</span>
        <br/>
        <span>{`${range}`}</span>
        <br/>
      </span>
    )

    return {
      key: '3-rf',
      equation: `$y = \\dfrac{${firstNumber}x - ${secondNumber}}{(${thirdNumber}x - ${fourthNumber})(${firstNumber}x - ${secondNumber})}$`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter4() {
    let firstNumber = randomIntFromInterval(2, 12)
    let secondNumber = randomIntFromInterval(2, 12)
    let thirdNumber = randomIntFromInterval(2, 12)
    let fourthNumber = randomIntFromInterval(2, 12)
    let fifthNumber = randomIntFromInterval(2, 12)
    let sixthNumber = randomIntFromInterval(2, 12)

    let frac1 = displayFraction(fourthNumber, thirdNumber)
    let frac2 = displayFraction(fourthNumber, sixthNumber)
    let frac3 = displayFraction(thirdNumber, fifthNumber)
    let frac3a = thirdNumber/fifthNumber
    let frac4 = displayFraction(sixthNumber, fifthNumber)
    let frac4a = sixthNumber/fifthNumber
    let frac5 = displayFraction(secondNumber, firstNumber)
    let frac5a = secondNumber/firstNumber
    let frac6 = displayFraction((secondNumber*thirdNumber) - (fourthNumber*firstNumber), (secondNumber*fifthNumber) - (sixthNumber*firstNumber))
    let frac6a = ((secondNumber*thirdNumber) - (fourthNumber*firstNumber))/((secondNumber*fifthNumber) - (sixthNumber*firstNumber))

    // frac4 frac5
    let simplifiedFrac5 = simplifyFraction(secondNumber, firstNumber).filter(n => n)
    let simplifiedFrac5Value = simplifiedFrac5[0] / (simplifiedFrac5[1] === null ? 1 : simplifiedFrac5[1])
    let simplifiedFrac4 = simplifyFraction(sixthNumber, fifthNumber).filter(n => n)
    let simplifiedFrac4Value = simplifiedFrac4[0] / (simplifiedFrac4[1] === null ? 1 : simplifiedFrac4[1])
   
    let domainbig = frac5
    let domainsmall= frac4
    if (frac4a > frac5a) {
      domainbig = frac4
      domainsmall = frac5
    }

    // frac3 frac6
    let simplifiedFrac3 = simplifyFraction(thirdNumber, fifthNumber).filter(n => n)
    let simplifiedFrac3Value = simplifiedFrac3[0] / (simplifiedFrac3[1] === null ? 1 : simplifiedFrac3[1])
    let simplifiedFrac6 = simplifyFraction(secondNumber*thirdNumber - fourthNumber*firstNumber, secondNumber*fifthNumber - sixthNumber*firstNumber).filter(n => n)
    let simplifiedFrac6Value = simplifiedFrac6[0] / (simplifiedFrac6[1] === null ? 1 : simplifiedFrac6[1])
   
    //let arr = [frac3a, frac6a]
    //let rangesmall = Math.min(...arr)
    //let rangebig = Math.max(...arr)

    let rangesmall = frac3
    let rangebig = frac6
    if (frac3a > frac6a) {
      rangesmall = frac6
      rangebig = frac3
    }

    let answerToDisplay = (
      <span>
        <span>{`$X-Intercept: (${frac1},0)$`}</span>
        <br/>
        <span>{`$Y-Intercept: (0, ${frac2})$`}</span>
        <br/>
        <span>{`$Holes: (${frac5}, ${frac6})$`}</span>
        <br/>
        <span>{`$Horizontal \\: Asymptote: y = ${frac3}$`}</span>
        <br/>
        <span>{`$Vertical \\: Asymptote: x = ${frac4}$`}</span>
        <br/>
        <span>{`$Domain: (-\\infty, ${domainsmall}) \\cup (${domainsmall}, ${domainbig}) \\cup (${domainbig}, + \\infty)$`}</span>
        <br/>
        <span>{`$Range: (-\\infty, ${rangesmall}) \\cup (${rangesmall}, ${rangebig}) \\cup (${rangebig}, +\\infty)$`}</span>
        <br/>
      </span>
    )

    return {
      key: '4-rf',
      equation: `$y = \\dfrac{(${firstNumber}x - ${secondNumber})(${thirdNumber}x - ${fourthNumber})}{(${fifthNumber}x - ${sixthNumber})(${firstNumber}x - ${secondNumber})}$`,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
    $$ y = \\frac{a_{n} x^{n} + a_{n - 1} x^{n - 1} \\hspace{1mm} + \\hspace{1mm} ... \\hspace{1mm} + \\hspace{1mm} a_{2} x^{2} + a_{1} x + a_{0}}{b_{m} x^{m} + b_{m - 1} x^{m - 1} + ... + b_{2} x^{2} + b_{1} x + b_{0}} $$
    \
      $$ \\text{1. Factor top and bottom.}  $$    
      \
      $$ \\text{2. Cancel out common factors to simplify function.} $$
      \
      $$ \\text{Simplified Function} = R(x) = \\frac{T(x)}{B(x)} $$
      \
      $$ \\text{3. Set canceled factor = 0, solve to get Hole x-coordinate.} $$
      \
      $$ \\text{4. Plug Step 3 in R(x) to get Hole y-coordinate.} $$
      \
      $$ \\text{5. X - Intercepts: T(x) = 0, solve for x.} $$
      \
      $$ \\text{6. Y - Intercept: x = 0, compute.} $$
      \
      $$ \\text{7. Vertical Asymptote: B(x) = 0, solve for x.} $$
      \
      $$ \\text{8. Horizontal Asymptote:} $$
      \
      $$ n > m \\rightarrow \\text{None} $$
      \
      $$ n = m \\rightarrow y = \\frac{a}{b} $$
      \
      $$ n < m \\rightarrow y = 0 $$
      \
      $$ \\text{Domain = all acceptable x-values} $$
      \
      $$ \\text{Domain} = \\mathbb{R} \\hspace{1mm} \\text{- x-coord of Holes - Vertical Asymptotes} $$
      \
      $$ \\text{Range = all acceptable y-values} $$
      \
      $$ \\text{Range} = \\mathbb{R} \\hspace{1mm} \\text{- y-coord of Holes - Horizontal Asymptotes} $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Rational Features"}
        instructions={"Compute all intercepts, holes, asymptotes, domain, and range."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Features Help", helpTextFunc: helpText}}
      />
    </div>
  );
}