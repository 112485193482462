import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { funcMappings } from '../../../../utils/calculusHelper';
import { randomNumFromSet } from '../../../../utils/mathHelpers';

export default function CalculusIndefiniteIntegral() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [3, 4, 5, 6, 7, 8 ,9];
    const funcMappingsHash = funcMappings()
    const funMappingKeys = Object.keys(funcMappingsHash)
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let randomFuncName = randomNumFromSet(funMappingKeys);


      let randomFuncObj = funcMappingsHash[randomFuncName];
      const { normalFunc, primeFunc, params } = randomFuncObj;
      let paramsForFunc = [];

      let x = 0
      while (x < params) {
        x = x + 1;
        paramsForFunc.push(randomNumFromSet(nums))
      }

      generatedProblem.push({
        key: `${step}-cii`,
        firstNormalExpression: normalFunc(paramsForFunc),
        firstPrimeExpression: primeFunc(paramsForFunc),
        name: randomFuncName
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\int ${equation.firstPrimeExpression}\\text{ } dx$`,
      answer: `$${equation.firstNormalExpression} + C$`
    }
  }

  return (
    <div>
      

      <EquationLayout
      title={"Calculus Indefinite Integral"}
      instructions={"Compute the derivative."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}