import React, { useState } from 'react';

import SimplicialComplex from './SimplicialComplex';
import Button from 'react-bootstrap/Button';

const SIMPLICIAL_COMPLEX = "simplicial_complex"
const FUNCTION_TYPES = [SIMPLICIAL_COMPLEX];

const functionTypeDisplayNames = {
  "simplicial_complex": "Simplicial Complex"
}

export default function Advanced() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(SIMPLICIAL_COMPLEX);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case SIMPLICIAL_COMPLEX: {
        return <SimplicialComplex />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}