import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticReduceFractions() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 5, 7, 11, 13];
    const nums2 = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let result1 = firstNumber*thirdNumber;
      let result2 = secondNumber*thirdNumber;

      generatedProblem.push({
        key: `${step}-ad`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        result2:result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.result1}}{${equation.result2}}$`,
      answer: `$${displayFraction(equation.firstNumber, equation.secondNumber)}$`
    }
  }

  function helpText() {
    return `$$
    
      { \\dfrac{60}{72}} $$
            
      \
    
      $$ {= \\dfrac{20 \\cdot 3}{24 \\cdot 3}} $$

      \

      $$ {= \\dfrac{20}{24}} $$

      \

      $$ {= \\dfrac{5 \\cdot 4}{6 \\cdot 4}} $$

      \

      $$ {= \\dfrac{5}{6}}

     $$`
  }

  return (
    <div>

      <EquationLayout
        title={"Arithmetic Reduce Fractions"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Reduce Fractions Help", helpTextFunc: helpText}}
      />
    </div>
  );
}